import React, { Component } from "react";
import axios from "axios";

class Resume extends Component {
  constructor(props) {
    super();

    this.state = {
      ResumeData: [],
    };
  }

  componentDidMount() {
    axios
      .get(this.props.source)
      .then((result) => {
        this.setState({
          ResumeData: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { ResumeData } = this.state;

    if (!ResumeData.header) {
      return (<div className="main-wrapper">Loading resume...</div>
      );
    }
    else
      return (
        <div className="main-wrapper">
          <article className="resume-wrapper theme-bg-light p-5 mt-4 shadow">
            <HeaderLinks header={ResumeData.header} />
            <hr />
            <HeaderIntro header={ResumeData.header} />
            <hr />

            <div className="resume-body">
              <div className="row">
                <div className="resume-main col-9 pr-0 pr-4">
                  <ExperienceSection experienceList={ResumeData.experience} />
                </div>

                <aside className="resume-aside col-3 px-4 pb-4">
                  <SkillsSection skillList={ResumeData.skills} />
                  <EducationSection educationList={ResumeData.education} />
                  <InterestsSection interestsList={ResumeData.interests} />
                </aside>
              </div>

              <hr />
              <div className="resume-footer text-center">
                <ul className="resume-social-list list-inline mx-auto mb-0 d-inline-block text-muted">
                  <li className="list-inline-item mb-lg-0 mr-3">
                    <a
                      className="resume-link"
                      href="https://linkedin.com/in/matthew-russell-81a2776"
                    >
                      <i
                        className="fab fa-linkedin fa-2x mr-2"
                        data-fa-transform="down-4"
                      ></i>
                      <span className="d-inline-block text-muted">
                        linkedin.com/matthew-russell
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <br></br>
            </div>
          </article>
        </div>
      );
  }
}

const HeaderLinks = ({ header }) => {
  let telLink = "tel:" + header.phone;
  let mailLink = "mailto:" + header.email;

  return (
    <div className="resume-header">
      {
        <div className="row align-items-center">
          <div className="resume-title col-8">
            <h2 className="resume-name mb-0 text-uppercase">{header.name}</h2>
            <div className="resume-tagline mb-3 mb-md-0">{header.title}</div>
          </div>
          <div className="resume-contact col-4">
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <i className="fas fa-phone-square fa-fw fa-lg mr-2 "></i>
                <a className="resume-link" href={telLink}>
                  {header.phone}
                </a>
              </li>
              <li className="mb-2">
                <i className="fas fa-envelope-square fa-fw fa-lg mr-2"></i>
                <a className="resume-link" href={mailLink}>
                  {header.email}
                </a>
              </li>
              <li className="mb-2">
                <i className="fas fa-globe fa-fw fa-lg mr-2"></i>
                <a className="resume-link" href={header.www}>
                  {header.www}
                </a>
              </li>
              <li className="mb-0">
                <i className="fas fa-map-marker-alt fa-fw fa-lg mr-2"></i>
                {header.location}
              </li>
            </ul>
          </div>
        </div>
      }
    </div>
  );
};

const HeaderIntro = ({ header }) => {
  return (
    <div className="resume-intro py-2">
      {
        <div className="row">
          <div className="col-12 text-left">{header.summary}</div>
        </div>
      }
    </div>
  );
};

const ExperienceSection = ({ experienceList }) => {
  return (
    <section className="work-section py-3">
      <h3 className="text-uppercase resume-section-heading mb-4">
        Work Experience
      </h3>
      {experienceList.map((item, index) => (
        <ExperienceItem key={index} item={item} />
      ))}
    </section>
  );
};

const ExperienceItem = ({ item }) => {
  return (
    <div className="item mb-4">
      <div className="row item-heading align-items-center mb-2">
        <h4 className="item-title col-6 mb-2 mb-md-0">{item.position}</h4>
        <div className="item-meta col-6 text-muted text-left text-md-right">
          <div className="row">
            <div className="col">{item.client}</div>
          </div>
          <div className="row">
            <div className="col">
              {item.dateFrom} - {item.dateTo}
            </div>
          </div>
        </div>
      </div>
      <div className="item-content">
        <p className="text-justify">{item.description}</p>
        <ul className="resume-list">
          {item.keyPoints.map((keyPoint, index) => (
            <li key={index}>{keyPoint}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SkillsSection = ({ skillList }) => {
  return (
    <section className="skills-section py-3">
      <h3 className="text-uppercase resume-section-heading mb-4">Skills</h3>
      <div className="item">
        <h4 className="item-title">Technical</h4>
        <ul className="list-unstyled resume-skills-list">
          {skillList.technical.map((skill, index) => (
            <li key={index} className="mb-2">
              {skill}
            </li>
          ))}
        </ul>
      </div>

      <div className="item">
        <h4 className="item-title">Professional</h4>
        <ul className="list-unstyled resume-skills-list">
          {skillList.professional.map((skill, index) => (
            <li key={index} className="mb-2">
              {skill}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const EducationSection = ({ educationList }) => {
  return (
    <section className="education-section py-3">
      <h3 className="text-uppercase resume-section-heading mb-4">Education</h3>
      <ul className="list-unstyled resume-education-list">
        {educationList.map((educationItem, index) => (
          <li key={index} className="mb-3">
            <div className="resume-degree font-weight-bold">
              {educationItem.qualification}
            </div>
            <div className="resume-degree-org text-muted">
              {educationItem.institution}
            </div>
            <div className="resume-degree-time text-muted">
              {educationItem.dateRangeYrs}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

const InterestsSection = ({ interestsList }) => {
  return (
    <section className="skills-section py-3">
      <h3 className="text-uppercase resume-section-heading mb-4">Interests</h3>
      <ul className="list-unstyled resume-interests-list mb-0">
        {interestsList.map((interestItem, index) => (
          <li key={index} className="mb-2">
            {interestItem}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Resume;
